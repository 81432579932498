<template>
  <div id="SearchResult">
    <div class="row">
      <Header v-on:get-data="GetData" />
      <List ref="List" />
    </div>
  </div>
</template>

<script>
import Header from '../components/SearchResult/Header';
import List from '../components/SearchResult/List';
import { GetMetaData } from '@/common/meta.js';
export default {
  name: 'SearchResult',
  components: {
    Header,
    List,
  },
  data() {
    return {
      meta_data: null,
    };
  },
  methods: {
    GetData() {
      this.$refs.List.GetData();
    },
  },
  mounted() {
    window.prerenderReady = true;
  },
  created() {
    this.meta_data = GetMetaData(
      '搜尋結果',
      '居家五金工具、專業五金工具，你需要的各式五金商品，盡在宏信五金行！',
      ''
    );
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
