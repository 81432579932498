<template src="./template.html"></template>

<script>
export default {
  name: "SearchResultHeader",
  data() {
    return {
      key_word: ""
    }
  },
  methods: {
    Search() {
      if (this.key_word != this.$route.query.name) {
        this.$router.push(`/search?name=${this.key_word}`)
        this.$emit("get-data")
      }
    }
  },
  created() {
    this.key_word = this.$route.query.name
  },
  watch: {
    "$route"() {
      this.key_word = this.$route.query.name
    }
  }
}
</script>