<template src="./template.html"></template>

<script>
export default {
  name: "SearchResultList",
  data() {
    return {
      not_found: false,
      sort_by: "預設排序"
    }
  },
  methods: {
    products() {
      this.not_found = false
      let tmp_products = JSON.parse(JSON.stringify(this.$store.state.products))
      tmp_products = tmp_products.filter(item => item.Title.indexOf(this.$route.query.name) != -1)
      if (tmp_products.length > 0) {
        return this.SortProducts(tmp_products)
      }
      else {
        this.not_found = true
        return []
      }
    },
    SortProducts(products) {
      if (this.sort_by == "預設排序") {
        return products.sort(function (a, b) {
          return a.Seq - b.Seq;
        })
      }
      else {
        products = products.sort(function (a, b) {
          const a_date = new Date(a.NewTime)
          const b_date = new Date(b.NewTime)
          return a_date - b_date;
        })
        if (this.sort_by == "上市時間由舊到新") {
          return products
        }
        else if (this.sort_by == "上市時間由新到舊") {
          return products.reverse()
        }
      }
    },
    GetSeries(id) {
      return this.$store.state.series.filter(item => item.ProductDirID == id)[0].Title
    }
  },
  filters: {
    wordbreak(val) {
      let word = ""
      let count = 0
      let max_count = 20

      if (window.innerWidth <= 640) {
        max_count = 20
      }
      for (let i = 0; i < val.length; i++) {
        let tmp_count = count
        let tmp_word = word
        // eslint-disable-next-line no-control-regex
        if (val[i].match(/[\u0000-\u00ff]/g)) {
          count += 1
          word += val[i]
        }
        else if (val[i].match(/[\u4e00-\u9fa5]/g)) {
          count += 2
          word += val[i]
        }
        else if (val[i].match(/[\uff00-\uffff]/g)) {
          count += 2
          word += val[i]
        }

        if (tmp_count < max_count && count > max_count) {
          word = tmp_word
          break
        }
        else if (count == max_count) {
          break
        }
      }
      if (word == val) {
        return word
      }
      else {
        return word + "..."
      }
    }
  }
}
</script>